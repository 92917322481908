<template>
  <div class="orders page">
    <div class="content">
      <div class="page_header">
        <h1>Заказы</h1>
<!--        <el-button type="primary" size="mini" @click="create" icon="el-icon-plus">Новый заказ</el-button>-->
      </div>
      <div id="filter">

        <div class="status ib">
          <el-select
              v-model="filter.status"
              multiple
              collapse-tags
              :loading="statusesLoading"
              size="mini"
              placeholder="Статус заказа"
              @change="changeFilter">
            <el-option
                v-for="status in statuses"
                :key="status.id"
                :label="status.title"
                :value="status.id">
                <span
                    :style="{
                    backgroundColor: status.bg,
                    display: 'inline-block',
                    verticalAlign: 'top',
                    width: '16px',
                    height: '16px',
                    marginRight: '8px',
                    marginTop: '8px'
                  }"></span>{{ status.title }}
            </el-option>
          </el-select>
        </div>
        <div class="query ib">
          <el-input
              v-model="filter.query"
              clearable
              size="mini"
              @keyup.enter.native="changeFilter"
              @change="changeFilter"
              placeholder="Поиск по номеру заказа, телефона или имени клиента и т.д."
          ></el-input>
        </div>
        <div class="visibility ib">
          <el-radio-group v-model="filter.visibility" size="mini" @change="changeFilter">
            <el-radio-button label="my">Только мои</el-radio-button>
            <el-radio-button label="office">Мой офис</el-radio-button>
            <el-radio-button label="all">Все заказы</el-radio-button>
          </el-radio-group>
        </div>
        <div class="date_range ib">
          <el-date-picker
              clearable
              v-model="filter.dateRange"
              type="daterange"
              align="left"
              size="mini"
              unlink-panels
              format="dd.MM.yyyy"
              value-format="dd.MM.yyyy"
              start-placeholder="Дата от"
              end-placeholder="до"
              :picker-options="datePickerOptions"
              @change="changeFilter"></el-date-picker>
        </div>
      </div>
      <div class="box" v-loading="loading">
        <div class="grid">
          <div
              class="order grid_row"
              v-for="order in orders"
              :key="order.id">
            <el-row :gutter="0">
              <el-col :xs="24" :sm="3">
                <span class="number">№{{ order.number }}</span>
                <div class="time">
                  <i class="el-icon-time"></i>
                  {{ moment(order.created_at).format("DD.MM.YYYY HH:mm") }}
                </div>
              </el-col>
              <el-col :xs="15" :sm="4" :offset="0">
                <div class="client">
                  <el-link
                      @click.stop.prevent
                      :underline="false"
                      class="client_link"
                      icon="el-icon-user">
                    <!-- <el-avatar size="small"> {{order.client.name[0] }} </el-avatar> -->
                    <span class="client_name">{{ order.client.name }}</span>
                  </el-link>
                </div>
              </el-col>
              <el-col :xs="9" :sm="3" :offset="0">
                <div class="contacts">
                  <template v-if="order.client.contacts">
                    <div
                        class="contact"
                        v-for="contact in order.client.contacts"
                        :key="contact.id">
                      <template class="" v-if="contact.type == 'phone'">
                        <el-link
                            :href="'tel:+7' + contact.formatted"
                            class="phone_link"
                            icon="el-icon-phone"
                            :underline="false">{{ contact.value }}
                        </el-link>
                      </template>
                      <template class="" v-if="contact.type == 'email'">
                        <el-link
                            :href="'mailto:' + contact.value"
                            class="email_link"
                            icon="el-icon-message"
                            :underline="false">{{ contact.value }}
                        </el-link>
                      </template>
                    </div>
                  </template>
                  <div class="contact empty" v-else>Нет контактов</div>
                </div>
              </el-col>
              <el-col :xs="15" :sm="3" :offset="0">
                <el-tag size="mini">
                  Сумма: <strong class="price"> {{ (order.sum_wd) | price }}р</strong>
                </el-tag>
                <el-tag size="mini" effect="dark" type="info" v-if="order.project">
                  {{ order.project.title }}
                </el-tag>
                <div class="comment" v-if="order.comment">
                  {{ order.comment }}
                </div>
              </el-col>
              <el-col :xs="24" :sm="3" :offset="0">
                <div v-if="order.payments">
                  <el-tag size="mini" type="success" v-for="pay in order.payments" :key="pay.id">
                    Платеж №{{pay.number}} - {{pay.sum | price}}р
                  </el-tag>
                </div>
                <div v-else>
                  <el-tag size="mini" type="info">Нет платежей</el-tag>
                </div>
              </el-col>
              <el-col :xs="9" :sm="4" :offset="0">
                <div class="status">
                  <el-tag
                      class="statue_badge"
                      size="small"
                      type="info"
                      :style="{
                    backgroundColor: order.status.bg,
                    borderColor: order.status.bg,
                    color: order.status.color,
                  }">
                    {{ order.status.title }}
                  </el-tag>
                </div>
              </el-col>
              <el-col :xs="15" :sm="2" :offset="0">
                <el-link
                    class="employee"
                    v-if="order.employee"
                    icon="el-icon-user"
                    type="info"
                    :underline="false">
                  {{ order.employee.surname }}
                  <span v-if="order.employee.name">{{ order.employee.name[0] }}.</span>
                  <span v-if="order.employee.patronymic">{{ order.employee.patronymic[0] }}.</span>
                </el-link>
                <div class="employee" v-else>Нет ответственного</div>
              </el-col>
              <el-col :xs="9" :sm="2" :offset="0" :style="{ textAlign: 'right' }">
                <el-button
                    type="primary"
                    size="mini"
                    @click="openOrder(order.id)"
                    icon="el-icon-view">Детали
                </el-button>
              </el-col>
            </el-row>

          </div>
        </div>
      </div>
      <div class="pagination">
        <el-pagination
            layout="prev, pager, next"
            :total="ordersSize"
            :page-size="filter.limit"
            :current-page="currentPage"
            :hide-on-single-page="true"
            @current-change="paginateChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {actionTypes as ordersActions} from "@/store/modules/orders";

import price from '@/filters/price'

export default {
  name: "Orders",
  data() {
    return {
      filter: {
        limit: this.$route.query.limit ? Number(this.$route.query.limit) : 50,
        offset: this.$route.query.offset ? Number(this.$route.query.offset) : 0,
        status: this.$route.query.status ? this.$route.query.status : [],
        query: this.$route.query.query ? this.$route.query.query : '',
        visibility: this.$route.query.visibility ? this.$route.query.visibility : 'office',
        dateRange: [],
      },
      datePickerOptions: {
        firstDayOfWeek: 1,
        shortcuts: [
          {
            text: "Неделя",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "Месяц",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "3 месяца",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ]
      }
    }
  },
  computed: {
    ...mapState({
      orders: state => _.orderBy(state.orders.list, ['created_at'], ['desc']),
      ordersSize: state => state.orders.size,
      loading: state => state.orders.loading,
      submitting: state => state.orders.submitting,
      statuses: state => state.statuses_for_orders.list,
      statusesLoading: state => state.statuses_for_orders.loading,
    }),
    currentPage() {
      return ((this.filter.offset + this.filter.limit) / this.filter.limit)
    },
  },
  methods: {
    create() {
      // TODO: Создание заказа
    },
    loadOrders() {
      this.$store.dispatch(ordersActions.get, this.filter)
    },
    clear() {
      this.$store.dispatch(ordersActions.clear)
    },
    paginateChange(page) {
      this.filter.offset = (page - 1) * this.filter.limit;
      this.changeRoute();
      this.loadOrders();
    },
    changeFilter() {
      this.filter.offset = 0
      this.changeRoute()
      this.loadOrders()
    },
    changeRoute() {
      this.$router
          .push({path: 'orders', query: this.filter})
          .catch((e) => {
            console.log('route not changed')
          })
    },
    openOrder(id) {
      this.$router.push({path: "/orders/" + id});
    },
  },
  mounted() {
    if (
        this.$route.query.dateRange != undefined &&
        typeof this.$route.query.dateRange == "object"
    ) {
      if (this.$route.query.dateRange.length > 0) {
        //TODO: Edit this code for set dates from string dormat
        this.filter.dateRange[0] = new Date(this.$route.query.dateRange[0]);
        this.filter.dateRange[1] = new Date(this.$route.query.dateRange[1]);
        console.log(this.filter.dateRange)
      }
    }
    this.loadOrders()
  },
  beforeDestroy() {
    this.clear()
  },
  filters: {
    price
  },
  created() {
    document.title = this.$route.meta.title ? this.$route.meta.title : 'GlassERP'
  }
}
</script>

